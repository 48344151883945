import Card from "components/card";
import React, { useState, useRef } from "react";

import { Input } from "@material-tailwind/react";
import { RiPencilFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";


const General = ({ userData, editProfile }) => {

  const [nameEditing, setNameEditing] = useState(false);
  const [roleEditing, setRoleEditing] = useState(false);
  const [companyEditing, setCompanyEditing] = useState(false);
  const [phoneEditing, setPhoneEditing] = useState(false);

  const nameRef = useRef();
  const roleRef = useRef();
  const companyRef = useRef();
  const phoneRef = useRef();

  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          General Information
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          Complete your profile by giving our team some basic information about yourself.
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Name</p>
          {!nameEditing ?
            <p className="text-base font-medium text-navy-700 dark:text-white flex flex-row gap-2 items-center justify-between w-full">
              {/* <span>{userData.data.user.email.split('@')[0]}</span> */}
              <span>{nameRef.current ? nameRef.current.value : (userData.data.user.workingPos ? userData.data.user.workingPos : "N/A")}</span>
              <RiPencilFill onClick={() => setNameEditing(true)} />
            </p>
          :
            <div className="flex flex-row items-center justify-between w-full">
              <input
                  className="rounded-xl border-2 border-gray-300 px-2 py-0 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  type="text"
                  ref={nameRef}
                  defaultValue={userData.data.user.workingPos ? userData.data.user.workingPos : ""}
                  style={{ width: "100px" }}
              />
              <FaCheck onClick={() => {setNameEditing(false);editProfile("workingPos", `${nameRef.current.value}`)}} />
            </div>
          }
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Role</p>
          {!roleEditing ?
            <p className="text-base font-medium text-navy-700 dark:text-white flex flex-row gap-2 items-center justify-between w-full">
              <span>{roleRef.current ? roleRef.current.value : (userData.data.user.companyRole ? userData.data.user.companyRole : "N/A")}</span>
              <RiPencilFill onClick={() => setRoleEditing(true)} />
            </p>
          :
            <div className="flex flex-row items-center justify-between w-full">
              <input
                  className="rounded-xl border-2 border-gray-300 px-2 py-0 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  type="text"
                  ref={roleRef}
                  defaultValue={userData.data.user.companyRole ? userData.data.user.companyRole : ""}
                  style={{ width: "100px" }}
              />
              <FaCheck onClick={() => {setRoleEditing(false);editProfile("companyRole", `${roleRef.current.value}`)}} />
            </div>
          }
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Company</p>
          {!companyEditing ?
            <p className="text-base font-medium text-navy-700 dark:text-white flex flex-row gap-2 items-center justify-between w-full">
              <span>{companyRef.current ? companyRef.current.value : (userData.data.user.companyName ? userData.data.user.companyName : "N/A")}</span>
              <RiPencilFill onClick={() => setCompanyEditing(true)} />
            </p>
          :
            <div className="flex flex-row items-center justify-between w-full">
              <input
                  className="rounded-xl border-2 border-gray-300 px-2 py-0 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  type="text"
                  ref={companyRef}
                  defaultValue={userData.data.user.companyName ? userData.data.user.companyName : ""}
                  style={{ width: "100px" }}
              />
              <FaCheck onClick={() => {setCompanyEditing(false);editProfile("companyName", `${companyRef.current.value}`)}} />
            </div>
          }
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Phone</p>
          {!phoneEditing ?
            <p className="text-base font-medium text-navy-700 dark:text-white flex flex-row gap-2 items-center justify-between w-full">
              <span>{phoneRef.current ? phoneRef.current.value : (userData.data.user.phoneNumber ? userData.data.user.phoneNumber : "N/A")}</span>
              <RiPencilFill onClick={() => setPhoneEditing(true)} />
            </p>
          :
            <div className="flex flex-row items-center justify-between w-full">
              <input
                  className="rounded-xl border-2 border-gray-300 px-2 py-0 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  type="text"
                  ref={phoneRef}
                  defaultValue={userData.data.user.phoneNumber ? userData.data.user.phoneNumber : ""}
                  style={{ width: "100px" }}
              />
              <FaCheck onClick={() => {setPhoneEditing(false);editProfile("phoneNumber", `${phoneRef.current.value}`)}} />
            </div>
          }
        </div>

        {/* <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Role</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            CISO
          </p>
        </div> */}

        {/* <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Company</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Test LLC.
          </p>
        </div> */}

        {/* <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Phone</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            12345678910
          </p>
        </div> */}
      </div>
    </Card>
  );
};

export default General;
