import { useDisclosure } from "@chakra-ui/hooks";
import React, { useRef, useState, useEffect } from 'react';
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "../default/components/Storage";
import Upload from "./components/Upload";
import axios from 'axios';
import { useCookies } from "react-cookie";
import CriticalModal from "components/modal/CriticalModal";
import CoolModal from "components/modal/CoolModal"
import { toast } from "react-toastify";


const ProfileOverview = ({ userData, scanData, planData, axiosInstance }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = useRef()
  const [cookies, removeCookie] = useCookies(["cookie-name"]);
  const [overallSecScore, setOverallSecScore] = useState(0);
  const [formattedScans, setFormattedScans] = useState([]);
  const [totFindings, setTotFindings] = useState(0);
  const [apiKey, setApiKey] = useState("");

  const actionDescription = "By clicking the 'Delete Account' button below, your CyberSanctus account will not be permanently deleted and your subscripton, if any, will be terminated. Once confirmed, this action cannot be undone and your data may be deleted.";

  useEffect((e) => {
    let customArray = [];
    let tempFindings = 0;
    let cumulativeSecScore = 0;
    scanData.map((scan) => {
      tempFindings = tempFindings + scan.vulns.length;
      let localSecScore = 0;

      const date = new Date(scan.createdAt);
      const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);

      scan.vulns.map((vuln) => {
        // //hidden console.log(vuln)
        // IMPLEMENT LOGIC TO CALCULATE SECURITY SCORE
        if(vuln.rating === "High"){
          cumulativeSecScore = cumulativeSecScore + 8;
          localSecScore = localSecScore + 8;
        } else if(vuln.rating === "Medium"){
          cumulativeSecScore = cumulativeSecScore + 4;
          localSecScore = localSecScore + 4;
        } else{
          cumulativeSecScore = cumulativeSecScore + 2;
          localSecScore = localSecScore + 2;
        }
      })

      customArray.push({
        name: 'default scan',
        date: formattedDate,
        lines: scan.lines,
        id: scan._id.slice(-4),
        score: 10 - (localSecScore / scan.vulns.length),
        report: scan.docUrl
      });
    })
    setFormattedScans(customArray);
    setTotFindings(tempFindings);
    setOverallSecScore(10 - (cumulativeSecScore / tempFindings));
  }, [scanData])

  const deleteAccount = async (e) => {
    try{
        const url = '/v1/users/deleteMe';
        const { data } = await axiosInstance.delete(
            url,
            {
                headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
        toast.success("Account Deleted. Redirecting You.");
        removeCookie("jwt");
        window.location.href = "https://cybersanctus.com";
    }
    catch(error){
        console.log(error)
        toast.error("Unable to Delete Account. Please Contact Support.")
    }
  }

  const editProfile = async (param, updated) => {
    try{
        const url = '/v1/users/me';
        const { data } = await axiosInstance.post(
            url,
            {
              [param]: `${updated}`
            },
            {
                headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
        toast.success(`Modification Successful`);
    }
    catch(error){
        toast.error("An unexpected error occurred. Please contact support.")
    }
  }

  const getApiKey = async () => {
    try{
        const url = '/v1/users/generate/key';
        const { data } = await axiosInstance.get(
            url,
            {
                headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
        setApiKey(data.apiKey)
    }
    catch(error){}
  }

  useEffect(() => {
    getApiKey();
  }, [])

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner axiosInstance={axiosInstance} userData={userData} planData={planData} scanData={scanData} secScore={Math.round(overallSecScore * 100)/100}/>
        </div>
        <div className="col-span-3 lg:!mb-0">
          <Storage userData={userData} planData={planData}/>
        </div>

        <div className="z-0 col-span-5 lg:!mb-0">
          {/* <Upload /> */}
          <General userData={userData} editProfile={editProfile} />
        </div>
      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">

        {/* col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3 */}
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Notification type="Scan Options" />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Notification type="Preferences" />
        </div>
      </div>

      <div className="flex flex-row justify-center gap-2">
        {userData.data.user.purchasedPackage ?
          (userData.data.user.purchasedPackage.package === "668d1c138af437da8e5ebe5b" ?
            <CoolModal actionName="View API Key" actionDescription={apiKey} />
            :
            null
          )
          :
          null
        }
        <CriticalModal action={deleteAccount} actionName="Delete Account" actionDescription={actionDescription} />
        {/* <CoolModal actionName="View API Key" actionDescription={apiKey} /> */}
        {/* <button className="rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-red-400 dark:hover:bg-red-300/10 dark:active:bg-red-200/10">
          Delete Account
        </button> */}
      </div>
    </div>
  );
};

export default ProfileOverview;
