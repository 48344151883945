import React from "react";

// Admin Imports
import MainDashboard from "views/default";
import NFTMarketplace from "views/marketplace";
import Team from "views/team";
import Profile from "views/profile";
import DataTables from "views/tables";
import InformationalOverview from "views/informational";
// import Billing from "views/billing/billing";
import Billing from "views/billing";

import { TbTextScan2 } from "react-icons/tb";
import { FaMoneyBills } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
import { FaInfoCircle } from "react-icons/fa";
import { PiContactlessPaymentFill } from "react-icons/pi";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
} from "react-icons/md";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: MainDashboard,
  },
  {
    name: "Scans",
    layout: "/admin",
    path: "scans",
    icon: <TbTextScan2 className="h-6 w-6" />,
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Team",
    layout: "/admin",
    path: "team",
    icon: <RiTeamFill className="h-6 w-6" />,
    component: Team,
    secondary: true,
  },
  {
    name: "Team Verification",
    layout: "/admin",
    path: "team/:verifyToken",
    icon: <RiTeamFill className="h-6 w-6" />,
    component: Team,
    secondary: true,
  },
  {
    name: "Billing",
    layout: "/admin",
    icon: <PiContactlessPaymentFill className="h-6 w-6" />,
    path: "billing",
    component: Billing,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: Profile,
  },
  // {
  //   name: "Informational",
  //   layout: "/admin",
  //   path: "informational",
  //   icon: <FaInfoCircle className="h-6 w-6" />,
  //   component: InformationalOverview,
  // },
];
export default routes;
