import React, { useState, useEffect, useRef } from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import profileBanner from "assets/img/profile.png";
import Card from "components/card";
import axios from 'axios';
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import { MdOutlineCameraAlt } from "react-icons/md";

const Banner = ({ userData, planData, scanData, secScore, axiosInstance }) => {

  const [userPlan, setUserPlan] = useState({"name": "Newbie"});
  const uploadRef = useRef(null);
  const [cookies, removeCookie] = useCookies(["cookie-name"]);

  //hidden console.log(planData)

  const identifyPlan = async (planid) => {
    if(planData){
      planData.map((plan) => {
        if(planid === plan._id){
          setUserPlan(plan);
        }
      })
    }
  }

  const changeProfile = async (image) => {

		const formData = new FormData();
    const url = `/v1/uploads/profile/images/${userData.data.user.email}`
		formData.append("pic", image);

		try{
        const resp = await axiosInstance({
          method: "post",
          url: url,
          data: formData,
          headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${cookies.jwt}` }
        });
        window.location.reload();
		 }catch(error){
	       toast.error("Upload Failed");
	   }
  };

  useEffect(() => {
    if(userData.data.user.purchasedPackage){
      identifyPlan(userData.data.user.purchasedPackage.package);
    }
  }, [planData])

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${profileBanner})` }}
      >
        <input hidden type="file" accept=".png, .jpg" ref={uploadRef} onChange={(e) => changeProfile(e.target.files[0])} />
        <div onClick={(e) => uploadRef.current.click() + e.preventDefault()} className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700 hover:cursor-pointer group">
          <img className="h-full w-full rounded-full hover:filter hover:grayscale hover:blur-[2px] transition ease-in-out" src={userData.data.user.profilePic} alt="" />
          <MdOutlineCameraAlt className="invisible group-hover:visible absolute h-[40px] w-[40px] pointer-events-none" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {/* {userData.data.user.email.split('@')[0]} */}
          {userData.data.user.workingPos ? userData.data.user.workingPos : userData.data.user.email.split('@')[0]}
        </h4>
        <p className="text-base font-normal text-gray-600">{userData.data.user.email}</p>
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">{userPlan.name}</p>
          <p className="text-sm font-normal text-gray-600">Plan</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {scanData.length}
          </p>
          <p className="text-sm font-normal text-gray-600">Scans</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {secScore}
          </p>
          <p className="text-sm font-normal text-gray-600">Avg. Score</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
