import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import TeamTable from "./components/TeamTable";
import ScanTable from './components/ScanTable';
import { columnsDataComplex } from "./variables/columnsData";
import { columnsTeamData } from './variables/columnsTeamData';
import tableDataComplex from "./variables/tableDataComplex.json";

import Widget from "components/widget/Widget";
import { MdBarChart, MdDataUsage } from "react-icons/md";
import { IoFlash } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { RiTeamFill } from "react-icons/ri";

import tableDataTopCreators from "views/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/marketplace/variables/tableColumnsTopCreators";
import HistoryCard from "../marketplace/components/HistoryCard";
import TopCreatorTable from "../marketplace/components/TableTopCreators";
import RegularModal from "components/modal/RegularModal";
import CriticalModal from 'components/modal/CriticalModal';

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

import axios from "axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

const Team = ({ userData, teamMembers, axiosInstance }) => {

    const actionDescription = "By clicking the 'Delete Team' button below, your team will be disbanded and any existing members will be kicked out. Once confirmed, this action cannot be undone and your team data may be deleted.";

    const [userPlan, setUserPlan] = useState({"name": "Newbie"});
    const [memberData, setMemberData] = useState([]);
    const [rawTeamScanData, setRawTeamScanData] = useState(null);
    const [formattedTeamScanData, setFormattedTeamScanData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
    const params = useParams();

    const createTeam = async (name) => {
        try{
            const url = '/v1/teams/create';
            const { data } = await axiosInstance.post(
                url,
                {
                  name: name,
                },
                {
                    headers: { authorization: `Bearer ${cookies.jwt}` }
                }
            );
            toast.success("Team Created!");
            window.location.reload();
        }
        catch(error){
            toast.error("Unable to Create Team. Please contact support.");
            console.log(error);
        }
    }

    const deleteTeam = async (name) => {
        try{
            const url = '/v1/teams/leave';
            const { data } = await axiosInstance.post(
                url,
                {},
                {
                    headers: { authorization: `Bearer ${cookies.jwt}` }
                }
            );
            toast.success("Team Deleted!");
            window.location.reload();
        }
        catch(error){
            toast.error("Unable to Delete Team. Please contact support.");
            console.log(error);
        }
    }

    const removeMember = async (id) => {
        try{
            const url = '/v1/teams/member/remove';
            const { data } = await axiosInstance.post(
                url,
                {
                    userId: id,
                },
                {
                    headers: { authorization: `Bearer ${cookies.jwt}` }
                }
            );
            toast.success("Member Removed.");
            window.location.reload();
        }
        catch(error){
            toast.error("Unable to Remove Member. Please contact support.");
            console.log(error);
        }
    }

    const acceptInvite = async () => {
        try{
            const url = `/v1/teams/invite/accept/${params.verifyToken}`;
            const { data } = await axiosInstance.post(
                url,
                {},
                {
                    headers: { authorization: `Bearer ${cookies.jwt}` }
                }
            );
            toast.success("Team Joined!");
            window.location.href = "/admin/team";
        }
        catch(error){
            toast.error("Team Token Invalid. Please Try Again Later.");
            console.log(error);
        }
    }

    const teamScans = async (name) => {
        try{
            const url = '/v1/teams/members/scans';
            const { data } = await axiosInstance.get(
                url,
                {
                    headers: { authorization: `Bearer ${cookies.jwt}` }
                }
            );

            console.log("TEAM SCANS:", data.scans);
            setRawTeamScanData(data.scans);
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        console.log(teamMembers)
        if(teamMembers){
            console.log(teamMembers)
            const adminUser = {
                "member": teamMembers.admin.email,
                "role": "admin",
                "loc-usage": teamMembers.admin.instantLines
            }
            setMemberData(memberData => [...memberData, adminUser])
            teamMembers.members.map((member) => {
                const newMember = {
                    "member": member.email,
                    "role": "member",
                    "loc-usage": member.instantLines,
                    "id": member._id
                }
                setMemberData(memberData => [...memberData, newMember])
            })

            if(teamMembers.admin._id === userData.data.user._id){
                setIsAdmin(true);
            }
        }
    }, [teamMembers])

    useEffect(() => {
        // console.log(rawTeamScanData)
        if(rawTeamScanData){
            console.log(rawTeamScanData)
            try{
                rawTeamScanData[0].map((scan) => {
                    console.log(scan)
                    const date = new Date(scan.createdAt);
                    const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);
                    const newScanEntry = {
                        "member": scan.user.workingPos ? scan.user.workingPos : scan.user.email,
                        "loc-usage": scan.lines,
                        "vulns": scan.vulns.length,
                        "date": formattedDate,
                        "report": scan.docUrl
                    }
                    setFormattedTeamScanData(formattedTeamScanData => [...formattedTeamScanData, newScanEntry])
                })
            } catch(error){}
        }
    }, [rawTeamScanData])

    useEffect(() => {
        console.log(formattedTeamScanData)
    }, [formattedTeamScanData])

    useEffect(() => {
        teamScans();
        if(params.verifyToken){
            acceptInvite();
        }
    }, [])

  return (
    <div>

        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Team Plan"}
            subtitle={teamMembers ? (teamMembers.admin.purchasedPackage ? teamMembers.admin.purchasedPackage.package.name : '') : 'N/A'}
            />
            <Widget
            icon={<MdDataUsage className="h-6 w-6" />}
            title={"Monthly Lines"}
            subtitle={userData.data.user.purchasedPackage ? userData.data.user.purchasedPackage.remainingLines : "N/A"}
            />
            <Widget
            icon={<RiTeamFill className="h-6 w-6" />}
            title={"Members"}
            subtitle={teamMembers ? teamMembers.members.length + 1 : ''}
            />
            <Widget
            icon={<RiAdminFill className="h-6 w-6" />}
            title={"Admin"}
            subtitle={teamMembers ? (teamMembers.admin.workingPos ? teamMembers.admin.workingPos : teamMembers.admin.email.split('@')[0]) : 'N/A'}
            // {teamMembers.admin.workingPos ? teamMembers.admin.workingPos : teamMembers.admin.email.split('@')[0]}
            />
        </div>

        <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">

            {teamMembers ?
                (isAdmin ? 
                    <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">

                        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
                            <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                            Manage {teamMembers.name}'s Team
                            </h4>
                            <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
                            <li>
                                {/* <a
                                className="text-base font-medium text-brand-500 text-red-500"
                                href=" "
                                >
                                Delete Team
                                </a> */}
                                <CriticalModal action={() => deleteTeam()} actionName="Delete Team" actionDescription={actionDescription} />
                            </li>
                            </ul>
                        </div>

                        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-1">
                            <TeamTable
                            isAdmin={isAdmin}
                            columnsData={columnsDataComplex}
                            // tableData={tableDataComplex}
                            tableData={memberData}
                            paginationBar={true}
                            removeMember={removeMember}
                            axiosInstance={axiosInstance}
                            />
                        </div>
                    </div>

                    :

                    <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">

                        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
                            <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                            {teamMembers.name}'s Team
                            </h4>
                            <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
                            <li>
                                {/* <a
                                className="text-base font-medium text-brand-500 text-red-500"
                                href=" "
                                >
                                Delete Team
                                </a> */}
                                <CriticalModal action={() => deleteTeam()} actionName="Leave Team" actionDescription={actionDescription} />
                            </li>
                            </ul>
                        </div>

                        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-1">
                            <TeamTable
                            isAdmin={isAdmin}
                            columnsData={columnsDataComplex}
                            // tableData={tableDataComplex}
                            tableData={memberData}
                            paginationBar={true}
                            removeMember={removeMember}
                            />
                        </div>
                    </div>
                )

                :

                <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">

                    <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
                        <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                        Create Your First Team
                        </h4>
                        <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
                        <li>
                            <RegularModal actionName="Create Team" action={(e) => createTeam(e)} />
                        </li>
                        </ul>
                    </div>

                    <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-1">
                        <Alert className='linear rounded-xl border-2 bg-white px-5 py-3 text-base font-medium text-brandSubtleLighter transition duration-200 dark:border-brandSubtle dark:bg-brandSubtleLighter/10 dark:text-white'
                            status='success'
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            >
                            <FaUsers className='h-10 w-10' />
                            <h1 className='mt-4 mb-1 font-semibold text-xl'>
                                Invite your Team to CodeHound!
                            </h1>
                            <p>
                                With CodeHound Business, you can now invite your team. Create a team above and start inviting co-workers.
                            </p>
                        </Alert>
                    </div>
                </div>
            }

            <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
                <ScanTable
                    isAdmin={isAdmin}
                    columnsData={columnsTeamData}
                    // tableData={tableDataComplex}
                    tableData={formattedTeamScanData}
                    paginationBar={true}
                    removeMember={removeMember}
                />
                {/* <TopCreatorTable
                extra="mb-5"
                tableData={tableDataTopCreators}
                columnsData={tableColumnsTopCreators}
                /> */}
                {/* <Card extra={"h-[300px] w-full overflow-hidden"}>
                <AspectRatio maxW='560px' ratio={16/14}>
                    <iframe
                    title='Usage Guide'
                    src={require("assets/mp4/promo.mp4")}
                    // allowFullScreen
                    />
                </AspectRatio>
                </Card> */}
                <HistoryCard axiosInstance={axiosInstance} />
            </div>
        </div>
    </div>
  );
};

export default Team;
