import Banner from "./components/Banner";
import Card from "components/card";
import { AspectRatio } from "@chakra-ui/layout"
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

import ComplexTable from "views/default/components/ComplexTable";
import { columnsDataCheck, columnsDataComplex } from "../default/variables/columnsData";

import tableDataTopCreators from "views/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/marketplace/variables/tableColumnsTopCreators";
import HistoryCard from "./components/HistoryCard";
import TopCreatorTable from "./components/TableTopCreators";


const Marketplace = ({ scanData, userData, teamMembers, axiosInstance }) => {

  const [formattedScans, setFormattedScans] = useState([]);
  const [totFindings, setTotFindings] = useState(0);
  const [overallSecScore, setOverallSecScore] = useState(0);
  const [memberData, setMemberData] = useState([]);

  useEffect((e) => {
    let customArray = [];
    let tempFindings = 0;
    let cumulativeSecScore = 0;
    scanData.map((scan) => {
      tempFindings = tempFindings + scan.vulns.length;
      let localSecScore = 0;

      const date = new Date(scan.createdAt);
      const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);

      scan.vulns.map((vuln) => {
        // //hidden console.log(vuln)
        // IMPLEMENT LOGIC TO CALCULATE SECURITY SCORE
        if(vuln.rating === "High"){
          cumulativeSecScore = cumulativeSecScore + 8;
          localSecScore = localSecScore + 8;
        } else if(vuln.rating === "Medium"){
          cumulativeSecScore = cumulativeSecScore + 4;
          localSecScore = localSecScore + 4;
        } else{
          cumulativeSecScore = cumulativeSecScore + 2;
          localSecScore = localSecScore + 2;
        }
      })

      customArray.push({
        name: 'default scan',
        date: formattedDate,
        lines: scan.lines,
        id: scan._id.slice(-4),
        score: 10 - (localSecScore / scan.vulns.length),
        report: scan.docUrl
      });
    })
    setFormattedScans(customArray);
    setTotFindings(tempFindings);
    setOverallSecScore(10 - (cumulativeSecScore / tempFindings));
  }, [scanData])

  useEffect(() => {
    console.log(teamMembers)
    if(teamMembers){
        console.log(teamMembers)
        const adminUser = {
            "name": [teamMembers.admin.workingPos ? teamMembers.admin.workingPos : teamMembers.admin.email, require('assets/svg/user.svg').default],
            "loc-usage": teamMembers.admin.instantLines,
            "percentage": 30
        }
        setMemberData(memberData => [...memberData, adminUser])
        teamMembers.members.map((member) => {
            const newMember = {
                "name": [member.workingPos ? member.workingPos : member.email, require('assets/svg/user.svg').default],
                "loc-usage": member.instantLines,
                "id": member._id,
                "percentage": 30
            }
            setMemberData(memberData => [...memberData, newMember])
        })
    }
  }, [teamMembers])
  
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        <Banner banneSize="Medium" axiosInstance={axiosInstance} />

        {/* NFt Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Scan History
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Docs
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Video
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Blog
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                <a href=" ">Contact</a>
              </a>
            </li>
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-1">
          {/* <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt3}
          /> */}
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={formattedScans}
            paginationBar={true}
          />
        </div>

        {/* Recenlty Added setion */}
        {/* <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Recently Added
          </h4>
        </div> */}

        {/* Recently Add NFTs */}
        {/* <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt4}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="ETH AI Brain"
            author="Nick Wilson"
            price="0.7"
            image={NFt5}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Mesh Gradients"
            author="Will Smith"
            price="2.91"
            image={NFt6}
          />
        </div> */}
      </div>

      {/* right side section */}

      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable
          extra="mb-5"
          // tableData={tableDataTopCreators}
          tableData={teamMembers ? memberData : tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
          userData={userData}
          teamMembers={teamMembers}
        />
        {/* <Card extra={"h-[300px] w-full overflow-hidden"}>
          <AspectRatio maxW='560px' ratio={16/14}>
            <iframe
              title='Usage Guide'
              src={require("assets/mp4/promo.mp4")}
              // allowFullScreen
            />
          </AspectRatio>
        </Card> */}
        <HistoryCard axiosInstance={axiosInstance} />
      </div>
    </div>
  );
};

export default Marketplace;
