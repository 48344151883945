export const columnsTeamData = [
  {
    Header: "Member",
    accessor: "member",
  },
  {
    Header: "LOC Usage",
    accessor: "loc-usage",
  },
  {
    Header: "Vulns",
    accessor: "vulns",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Report",
    accessor: "report",
  }
];
