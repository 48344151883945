import {useEffect} from 'react';
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError, MdChevronRight } from "react-icons/md";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { BsArrowRightSquareFill, BsArrowLeftSquareFill, BsFillSkipForwardBtnFill, BsFillSkipBackwardBtnFill } from "react-icons/bs";

import { useMemo } from "react";
import Progress from "components/progress";

const platinumBadge = require("../../../assets/img/platinum.png")
const goldBadge = require("../../../assets/img/gold.png")
const silverBadge = require("../../../assets/img/silver.png")
const bronzeBadge = require("../../../assets/img/bronze.png")

const ComplexTable = (props) => {
  const { columnsData, tableData, paginationBar } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
            {
                id: 'date',
                desc: true
            }
        ]
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,


    initialState,

    state: { pageIndex, pageSize }
  } = tableInstance;

  initialState.pageSize = 3;
  tableInstance.pageSize = 3;

  useEffect(() => {
    setPageSize(3);
  }, [])

  useEffect(() => {
    {paginationBar ? setPageSize(10) : setPageSize(3)}
  }, [paginationBar])


  return (
    <Card extra={`w-full ${paginationBar ? 'min-h-96' : 'h-80'} px-6 pb-6 sm:overflow-x-auto`}>
      <div class="relative flex items-center justify-between pt-4">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
          Recent Scans
        </div>
        <CardMenu />
      </div>

      {/* xl:overflow-hidden */}
      <div class={`mt-8 overflow-x-scroll xl:overflow-scroll ${paginationBar ? 'min-h-96' : 'h-80'}`}>
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* Start a scan and come back here to see the results! */}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    //hidden console.log(cell.column.Header)
                    if (cell.column.Header === "Contract") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Score") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value >= 9.0 ? (
                              // <MdCheckCircle className="text-green-500" />
                              <img src={platinumBadge} width={15}/>
                            ) : cell.value >= 8.0 ? (
                              // <MdCancel className="text-red-500" />
                              <img src={goldBadge} width={15}/>
                            ) : cell.value >= 6.0 ? (
                              // <MdOutlineError className="text-orange-500" />
                              <img src={silverBadge} width={15}/>
                            ) : cell.value >= 5.0 ? (
                              // <MdOutlineError className="text-orange-500" />
                              <img src={bronzeBadge} width={15}/>
                            ) : cell.value < 5.0 ? (
                              <MdOutlineError className="text-brandSubtle" />
                            ) : null}

                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {/* {cell.value} */}
                            {Math.round(cell.value * 10)/10}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "ID") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }else if (cell.column.Header === "Date") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Report") {
                      // data = <Progress width="w-[108px]" value={cell.value} />;
                      data = <button onClick={() => window.open(cell.value)} className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">
                      Open <MdChevronRight className="text-lg" />
                    </button>
                    }
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {paginationBar ? 
        <div className="pagination relative flex items-center justify-between pt-4">
          <span>
            Page{" "}
            <strong>
              {tableInstance.state.pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          {/* <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={tableInstance.state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "} */}
          <select className="rounded-xl dark:bg-navy-800 dark:text-white dark:hover:bg-none dark:active:bg-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <span className="flex flex-row items-center gap-2">
            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="flex flex-row items-center rounded-xl px-3 py-2 text-xs font-medium text-gray-800 transition duration-200 dark:bg-none dark:text-white">
              {/* {"<"} */}
              <FaArrowLeft />
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage} className="flex flex-row items-center rounded-xl px-3 py-2 text-xs font-medium text-gray-800 transition duration-200 dark:bg-none dark:text-white">
              {/* {">"} */}
              <FaArrowRight />
            </button>
          </span>
        </div>

        :

        <></>
      }

      
    </Card>
  );
};

export default ComplexTable;
