export const columnsDataComplex = [
  {
    Header: "Member",
    accessor: "member",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "LOC Usage",
    accessor: "loc-usage",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "ID",
    accessor: "id",
  }
];
