import Card from "components/card";
import Progress from "components/progress";
import React, { useMemo, useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from 'react-router-dom';

import { FaLock } from "react-icons/fa";

function TopCreatorTable(props) {
  const { columnsData, tableData, userData, teamMembers } = props;
  const navigate = useNavigate();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [businessClient, setBusinessClient] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  useEffect(() => {
    if(userData){
      if(userData.data.user.purchasedPackage){
        if(userData.data.user.purchasedPackage.package === "668d1c138af437da8e5ebe5b"){
          setBusinessClient(true);
        } else {
          setBusinessClient(false);
        }
      }
    }
  }, [])

  useEffect(() => {
    if(teamMembers){
      setBusinessClient(true);
    }
  }, [teamMembers])

  return (

    <Card extra={"h-[300px] w-full overflow-hidden"}>
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] z-[1] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Team Members
        </h4>
        <button onClick={() => toast.warn("This feature is temporarily unavailable.")} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          Invite
        </button>
      </div>

      {/* height: 160%;
        overflow: hidden;
        width: 100vw;
        position: absolute;
        z-index: -1;
        backdrop-filter: blur(200px); */}

      {!businessClient || !teamMembers ? 
      <div style={{ position: 'absolute', width: '100%', height: '100%', left: '50%', top: '50%' }}>
        {/* <h1 style={{ position: 'absolute', zIndex: '1' }}>Test.</h1> */}
        <div style={{ position: 'relative', zIndex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em', left: '-50%', top: '-15%' }}>
          <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[15px] text-3xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
            <FaLock />
          </div>
          <p>{!businessClient ? "Upgrade to use this feature" : "You Don't Have Any Team Members"}</p>
          <button onClick={() => {businessClient && !teamMembers ? navigate("/admin/team") : navigate("/admin/billing")}} className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">{!businessClient ? "Unlock Now" : "Invite Now"}</button>
        </div>
        <span style={{ height: '100%', overflow: 'hidden', width: '100%', position: 'absolute', backdropFilter: 'blur(5px)', zIndex: '0', left: '-50%', top: '-50%' }}/>
      </div>

      :
      <></>
      }

      {/* Top Creator Heading */}
      <div className={`w-full ${businessClient ? 'overflow-x-scroll' : 'overflow-hidden'} px-4`}>
        <table
          {...getTableProps()}
          className="w-full min-w-[500px] overflow-x-scroll"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="px-4">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="h-[30px] w-[30px] rounded-full">
                            <img
                              src={cell.value[1]}
                              className="h-full w-full rounded-full"
                              alt=""
                            />
                          </div>
                          <p className="text-sm font-medium text-navy-700 dark:text-white">
                            {cell.value[0]}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "LOC Usage") {
                      data = (
                        <p className="text-md font-medium text-gray-600 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Percentage") {
                      data = (
                        <div class="mx-2 flex font-bold">
                          <Progress width="w-16" value={cell.value} />
                        </div>
                      );
                    }
                    return (
                      <td
                        className="py-3 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default TopCreatorTable;