import nft1 from "assets/img/nfts/NftBanner1.png";
import cryptoVector from "assets/img/cryptobanner.png"
import cryptoVector2 from "assets/img/cryptobanner2.png"
import { useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { background } from "@chakra-ui/system";
import { Spinner } from '@chakra-ui/react'

const Banner1 = (props) => {

  const { bannerSize, axiosInstance } = props;
  const uploadRef = useRef(null);
  const [scanZIP, setScanZIP] = useState(null);
  const [cookies] = useCookies(["cookie-name"]);
  const [scanLoading, setScanLoading] = useState(false);

  const scanFile = async (e) => {
    //hidden console.log("[+] SCAN FILE INVOKED.")
    if (scanZIP){
      const formData = new FormData();
      formData.append("file", scanZIP);
      const link = '/v1/users/scanFile';

      try{
        toast.info("Scan Started");
        setScanLoading(true);
        const resp = await axiosInstance({
          method: "post",
          url: link,
          data: formData,
          headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${cookies.jwt}` }
        });
        toast.success("Scan Sucessful");
        setScanLoading(false);
      }catch(error){
        toast.error("Scan Failed");
        setScanLoading(false);
        //hidden console.log(error)
      }
    } else{
      toast.error("You must upload a ZIP file first!")
    }
  }

  return (
    <div
      className="flex w-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
      style={{ backgroundImage: `url(${bannerSize === "Large" ? cryptoVector : cryptoVector2})` }}
    >
      <div className="w-full">
        <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          Scan a new Smart Contract
        </h4>
        <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
          Upload your solidity smart contract for CodeHound to start sniffing. Make sure to ZIP your smart contract(s) up and upload them here for scanning.
        </p>

        <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
          <input hidden type="file" accept=".sol, .zip" ref={uploadRef} onChange={(e) => setScanZIP(e.target.files[0])} />
          <button onClick={(e) => uploadRef.current.click() + e.preventDefault()} className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
            Upload ZIP
          </button>
          <button onClick={(e) => scanFile(e)} className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">Submit
            {scanLoading ?
              <Spinner 
              thickness='3px'
              speed='0.65s'
              className="w-4 h-4 text-sm shadow-xl text-brand-500"
              />
              :
              null
            }
          </button>          
          <button
            href=" "
            className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2"
          >
            {/* Terms of Service */}
            {scanZIP ? scanZIP.name : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner1;
