import React, { useState, useEffect } from "react";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";

import { FaEthereum } from "react-icons/fa";
import Card from "components/card";
import GitHubLinkScan from "./GitHubLinkScan";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'

import { AiFillGithub } from "react-icons/ai";
import { PiPlugsConnectedFill } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import { Spinner } from '@chakra-ui/react'

const HistoryCard = ({ userData, axiosInstance }) => {

  const [repoArray, setRepoArray] = useState(null);
  const [scanLoading, setScanLoading] = useState(false);
  const [docUrl, setDocUrl] = useState(null);

  const navigate = useNavigate("");
  const [cookies, removeCookie] = useCookies(["cookie-name"]);

  const fetchRepos = async (e) => {
    try{
        const url = '/v1/users/repos';
        const { data } = await axiosInstance.get(
            url,
            {
                headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
        setRepoArray(data.Repos);
    }
    catch(error){
        console.log(error);
    }
  }

  const scanRepo = async (link) => {
    try{
        const githubLink = link.replace("https://api.github.com/repos", "https://github.com");
        const url = '/v1/users/githubScanFile';

        toast.info("Scan Started");
        setScanLoading(true);

        const { data } = await axiosInstance.post(
            url,
            {
              "url": githubLink,
            },
            {
                headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );

        console.log(data)
        toast.success("Scan Sucessful");
        setDocUrl(data.data.scan.docUrl);
        setScanLoading(false);
    }
    catch(error){
        console.log(error);
        toast.error("Scan Failed");
        setScanLoading(false);
    }
  }

  const handleRedirect = () => {
    if(docUrl){
      window.open(docUrl);
      window.location.reload();
    }
  }

  useEffect(() => {
    fetchRepos();
  }, [userData])

  return (
    <Card extra={"px-6 !z-5 overflow-hidden h-80"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3 z-[1]">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Scan From Github
        </div>
        {!userData.data.user.githubToken ?
          <div className="flex flex-row gap-2">
          <button className="flex flex-row items-center gap-[0.2em] linear rounded-[20px] bg-[#bf1f1f]/60 px-3 py-2 text-base font-medium text-white transition duration-200 dark:bg-[#e02626]/40">
            <GoDotFill color="#ed3939" style={{ width: '20px', height: '20px'}}/>
            <>Not Connected</>
          </button>
          <GitHubLinkScan action={scanRepo} scanLoading={scanLoading} docUrl={docUrl} />
          {/* <CardMenu /> */}
          </div>

          :

          <div className="flex flex-row gap-2">
          <button className="flex flex-row items-center gap-[0.2em] linear rounded-[20px] bg-[#1fbf2a]/60 px-3 py-2 text-base font-medium text-white transition duration-200 dark:bg-[#1fbf2a]/40">
            <GoDotFill color="#3ced39" style={{ width: '20px', height: '20px'}}/>
            <>Connected</>
          </button>
          <GitHubLinkScan action={scanRepo} scanLoading={scanLoading} docUrl={docUrl} />
          </div>
          
        }
      </div>

      {/* History CardData */}
      {repoArray?
        <div className="overflow-y-scroll h-[100%] flex flex-col items-start items-baseline content-start justify-start">
          {repoArray.map((repo, index) => (
            <div className="flex h-full w-full items-center justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center object-cover	">
                  <AiFillGithub className="h-full w-full rounded-xl aspect-square object-cover"/>
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    {repo.name.length > 20 ? repo.name.substring(0,20) + "..." : repo.name}
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    {repo.description ? (repo.description.length > 30 ? repo.description.substring(0,20) + "..." : repo.description) : (repo.private ? "Private Repository" : "Public Repository")}{" "}
                  </p>
                </div>
              </div>

              <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
                  {/* <button onClick={() => scanRepo(repo.url)} className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Scan Now</button> */}
                  <button onClick={(e) => {docUrl ? handleRedirect(e) : scanRepo(repo.url)}} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5em'}} className={"text-white linear rounded-xl px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brandSubtleLighter" + (docUrl ? " bg-brand-500 hover:!bg-[#db8e76] text-white" : " bg-brandSubtle")}><span>{docUrl ? 'Open' : 'Scan Now'}</span><span>{docUrl ? <FaArrowRight /> : null}</span>
                    {scanLoading ?
                      <Spinner 
                      thickness='3px'
                      speed='0.65s'
                      className="w-4 h-4 text-sm shadow-xl text-brand-500"
                      />
                      :
                      null
                    }
                  </button> 
                </div>
              </div>
            </div>
          ))}
        </div>

        :

        <div className="overflow-y-hidden h-full flex flex-col items-start items-baseline content-start justify-start">

            <div style={{ position: 'absolute', width: '100%', height: '100%', left: '50%', top: '50%' }}>
              <div style={{ position: 'relative', zIndex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em', left: '-50%', top: '-15%' }}>
                <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[15px] text-3xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                  <PiPlugsConnectedFill />
                </div>
                <p>Connect to GitHub to gain access</p>
                <button onClick={() => toast.info("Please use the 'Connect to GitHub' widget Below.")} className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Connect Now</button>
              </div>
              <span style={{ height: '100%', overflow: 'hidden', width: '100%', position: 'absolute', backdropFilter: 'blur(5px)', zIndex: '0', left: '-50%', top: '-50%' }}/>
            </div>

            <div className="flex h-full w-full items-center justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center object-cover	">
                  <AiFillGithub className="h-full w-full rounded-xl aspect-square object-cover"/>
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    Example Smart Contract Repository
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    Private Repository
                  </p>
                </div>
              </div>

              <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
                  <button className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Scan Now</button>
                </div>
              </div>
            </div>

            <div className="flex h-full w-full items-center justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center object-cover	">
                  <AiFillGithub className="h-full w-full rounded-xl aspect-square object-cover"/>
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    Example Smart Contract Repository
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    Private Repository
                  </p>
                </div>
              </div>

              <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
                  <button className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Scan Now</button>
                </div>
              </div>
            </div>

            <div className="flex h-full w-full items-center justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center object-cover	">
                  <AiFillGithub className="h-full w-full rounded-xl aspect-square object-cover"/>
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    Example Smart Contract Repository
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    Private Repository
                  </p>
                </div>
              </div>

              <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
                  <button className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Scan Now</button>
                </div>
              </div>
            </div>

            <div className="flex h-full w-full items-center justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center object-cover	">
                  <AiFillGithub className="h-full w-full rounded-xl aspect-square object-cover"/>
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    Example Smart Contract Repository
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    Private Repository
                  </p>
                </div>
              </div>

              <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
                  <button className="flex flex-row items-center rounded-xl bg-brandSubtle px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-brandSubtleLighter active:bg-brandSubtleLighter dark:bg-brandSubtle dark:text-white dark:hover:bg-brandSubtleLighter dark:active:bg-brandSubtleLighter">Scan Now</button>
                </div>
              </div>
            </div>

        </div>
      }
    </Card>
  );
};

export default HistoryCard;
