import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";

const PricingCard = (props) => {

    const plan = props.plan;
    const userPlan = props.userPlan;
    const optionType = props.type;
    const navigate = useNavigate();

    const firebaseEASM = 'https://firebasestorage.googleapis.com/v0/b/hackplaza-40bf8.appspot.com/o/Sample-Reports%2FSurface%20Management.pdf?alt=media&token=3e7f7994-00af-4812-8a41-89890d153f4e'

  return (
    optionType === "monthly" ? 

    <div className={'plans-plan plan-'+plan._id + ' bg-white text-black p-4 rounded-xl w-full flex flex-col items-center space-y-4 dark:bg-pricingbg dark:text-white'} key={plan._id}>
        <h2 className={`w-full py-2 text-center text-white ${plan._id === '63615ab136d8763ae46acd5b' ? 'bg-gradient-to-r from-indigo-500 to-purple-600' : plan._id === '635f9f63e3643a9bd03e0a6d' ? 'bg-gradient-to-r from-pink-500 to-orange-500' : 'bg-gradient-to-r from-indigo-300 to-teal-400'} rounded-t-xl`}>{plan.name}</h2>
        <div className='flex flex-col items-center space-y-2'>
            <h1 className="text-3xl">{plan.name === 'Newbie' ? plan.price : '£'+plan.price}</h1>
            <p className="text-center">{plan.description}</p>
            <ul className="list-none space-y-2 text-left pl-4">
                {
                    plan.features.map((feature, index) => (
                        <li key={index} className="flex flex-row list-none align-center gap-2" ><FaCheck style={{ color: '#FB9758' }}/><>{feature}</></li>
                    ))
                }
                {
                    plan.limits.map((feature, index) => (
                        <li key={index} className="flex flex-row list-none align-center gap-2" ><RxCross1 style={{ color: '#BEB3D8' }} /><>{feature}</></li>
                    ))
                }
            </ul>
        </div>
        <div className='plans-buttons flex space-x-2 mt-4'>
            {/* className={plan._id === '635f9f2de3643a9bd03e0a69' ? 'button-closeby' : ''} */}
            <button onClick={() => window.open(`https://cybersanctus.com/payment/${plan._id}/pending`)} className={`py-2 px-4 rounded-xl text-white ${plan._id === '635f9f2de3643a9bd03e0a69' ? 'rounded-l-md' : 'rounded-xl'} ${plan.name === userPlan.name ? 'bg-gray-500' : 'bg-gradient-to-r from-pink-600 to-orange-400 hover:shadow-lg'}`}>{plan.name === userPlan.name ? 'Current Plan' : (plan.price > userPlan.price ? 'Downgrade Plan' : 'Upgrade Plan')}</button>
            {plan._id === '635f9f2de3643a9bd03e0a69' ? <button className='download-button w-6' onClick={() => window.open(firebaseEASM)}><img src={require('../../../assets/svg/download.svg').default} /></button> : null}
        </div>
    </div>

    :

    <div className={'plans-plan plan-'+plan._id + ' bg-white p-4 rounded-xl w-full flex flex-col items-center space-y-4 text-black dark:bg-pricingbg dark:text-white'} key={plan._id}>
        <h2 className={`w-full py-2 text-center text-white ${plan._id === '63615ab136d8763ae46acd5b' ? 'bg-gradient-to-r from-indigo-500 to-purple-600' : plan._id === '635f9f63e3643a9bd03e0a6d' ? 'bg-gradient-to-r from-pink-500 to-orange-500' : 'bg-gradient-to-r from-indigo-300 to-teal-400'} rounded-t-xl`}>{'£'+plan.price}</h2>
        <div className='flex flex-col items-center space-y-2'>
            <h1 className="text-3xl">{plan.name}</h1>
        </div>
        <div className='plans-buttons flex space-x-2 mt-4'>
            <button onClick={() => window.open(`https://cybersanctus.com/payment/${plan._id}/instant-pending`)} className={`py-2 px-4 rounded-xl text-white ${plan._id === '635f9f2de3643a9bd03e0a69' ? 'rounded-l-md' : 'rounded-xl'} bg-gradient-to-r from-pink-600 to-orange-400 hover:shadow-lg`} >{plan._id !== '63615ab136d8763ae46acd5b' && plan._id != '635f9f63e3643a9bd03e0a6d' ? 'Buy Lines' : 'Contact Us'}</button>
            {plan._id === '635f9f2de3643a9bd03e0a69' ? <button className='download-button w-6' onClick={() => window.open(firebaseEASM)}><img src={require('../../../assets/svg/download.svg').default} /></button> : null}
        </div>
    </div>

    // <div className={'plans-plan plan-'+plan._id + ' bg-pricingbg p-4 rounded-xl w-full flex flex-col items-center space-y-4 text-white'} key={plan._id}>
    //     <h2 className={`w-full py-2 text-center text-white ${plan._id === '63615ab136d8763ae46acd5b' ? 'bg-gradient-to-r from-indigo-500 to-purple-600' : plan._id === '635f9f63e3643a9bd03e0a6d' ? 'bg-gradient-to-r from-pink-500 to-orange-500' : 'bg-gradient-to-r from-indigo-300 to-teal-400'} rounded-t-xl`}>{plan.name}</h2>
    //     <div className='flex flex-col items-center space-y-2'>
    //         <h1 className="text-3xl">{plan._id === '63615ab136d8763ae46acd5b' || plan._id === '635f9f63e3643a9bd03e0a6d' ? '£'+plan.price+'+' : '£'+plan.price}</h1>
    //         <p className="text-center">{plan.description}</p>
    //         <ul className="list-none space-y-2 text-left pl-4">
    //             {
    //                 plan.features.map((feature, index) => (
    //                     <li key={index} className="bg-no-repeat bg-left-top pl-6" style={{ backgroundImage: `url(${require('../../../assets/img/check.png')})` }}>{feature}</li>
    //                 ))
    //             }
    //         </ul>
    //     </div>
    //     <div className='plans-buttons flex space-x-2 mt-4'>
    //         {/* className={plan._id === '635f9f2de3643a9bd03e0a69' ? 'button-closeby' : ''} */}
    //         <button className={`py-2 px-4 rounded-xl text-white ${plan._id === '635f9f2de3643a9bd03e0a69' ? 'rounded-l-md' : 'rounded-xl'} bg-gradient-to-r from-pink-600 to-orange-400 hover:shadow-lg`} onClick={() => plan._id === '63615ab136d8763ae46acd5b' || plan._id === '635f9f63e3643a9bd03e0a6d' ? window.open('mailto:info@cybersanctus.com') : navigate(`/payment/${plan._id}`)}>{plan._id !== '63615ab136d8763ae46acd5b' && plan._id != '635f9f63e3643a9bd03e0a6d' ? 'Choose Plan' : 'Contact Us'}</button>
    //         {plan._id === '635f9f2de3643a9bd03e0a69' ? <button className='download-button w-6' onClick={() => window.open(firebaseEASM)}><img src={require('../../../assets/svg/download.svg').default} /></button> : null}
    //     </div>
    // </div>
  );
};

export default PricingCard;