import nft1 from "assets/img/nfts/NftBanner1.png";
import cryptoVector from "assets/img/cryptobanner.png"
import cryptoVector2 from "assets/img/cryptobanner2.png"
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { background } from "@chakra-ui/system";
import { Spinner } from '@chakra-ui/react'

import { FaArrowRight } from "react-icons/fa";
import { FaEthereum } from "react-icons/fa";
import { SiBinance } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import { SiFantom } from "react-icons/si";

const Banner1 = (props) => {

  const { bannerSize, axiosInstance } = props;
  const uploadRef = useRef(null);
  const [scanZIP, setScanZIP] = useState(null);
  const [cookies] = useCookies(["cookie-name"]);
  const [scanLoading, setScanLoading] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [drodownHidden, setDropdownHidden] = useState(true);
  const [selectedChain, setSelectedChain] = useState(null);
  const [chainID, setChainID] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [addressValue, setAddressValue] = useState("");
  const addressRef = useRef();

  function isEVMAddress() {
    // Check if the address starts with '0x', is 42 characters long, and only contains valid hex characters
    let isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(addressRef.current.value);
    setIsValidAddress(isValidAddress);
    setAddressValue(addressRef.current.value);
  }

  const scanFile = async (e) => {
    //hidden console.log("[+] SCAN FILE INVOKED.")
    if (scanZIP){
      const formData = new FormData();
      formData.append("file", scanZIP);
      const link = '/v1/users/scanFile';

      try{
        toast.info("Scan Started");
        setScanLoading(true);
        const resp = await axiosInstance({
          method: "post",
          url: link,
          data: formData,
          headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${cookies.jwt}` }
        });
        toast.success("Scan Sucessful");
        setDocUrl(resp.data.scan.docUrl);
        setScanLoading(false);
      }catch(error){
        toast.error("Scan Failed");
        setScanLoading(false);
        //hidden console.log(error)
      }
    } else{
      toast.error("You must upload a ZIP file first!")
    }
  }

  const scanAddress = async (e) => {
    //hidden console.log("[+] SCAN FILE INVOKED.")
    if (addressValue != ""){
      const link = '/v1/users/scanAddress';

      try{
        toast.info("Scan Started");
        setScanLoading(true);
        // const resp = await axiosInstance({
        //   method: "post",
        //   url: link,
        //   headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${cookies.jwt}` }
        // });
        const resp = await axiosInstance.post(
            link,
            {
              "address": addressValue,
              "chain": chainID,
            },
            {
              headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
        toast.success("Scan Sucessful");
        setDocUrl(resp.data.scan.docUrl);
        setScanLoading(false);
      }catch(error){
        toast.error("Scan Failed");
        setScanLoading(false);
        //hidden console.log(error)
      }
    } else{
      toast.error("You must upload a ZIP file first!")
    }
  }

  const handleRedirect = () => {
    if(docUrl){
      window.open(docUrl);
      window.location.reload();
    }
  }

  return (
    <div
      className="flex w-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
      style={{ backgroundImage: `url(${bannerSize === "Large" ? cryptoVector : cryptoVector2})` }}
    >
      <div className="w-full">
        <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          Scan a new Smart Contract
        </h4>
        <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
        Upload your solidity smart contract for CodeHound to start sniffing. Place the files in a ZIP archive or input an EVM smart contract address.
        </p>

        <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-5">
          <input hidden type="file" accept=".sol, .zip" ref={uploadRef} onChange={(e) => setScanZIP(e.target.files[0])} />
          <input
            disabled={scanZIP}
            className="linear text-navy-700 rounded-xl bg-bg-white hover:bg-white/90 w-[20%] min-w-[15em] px-5 py-2 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:text-black"
            style={{"border": addressValue ? (isValidAddress ? "1px solid green" : "1px solid #ed3939") : "", backgroundColor: scanZIP ? "#dedede" : "white"}}
            placeholder="Smart Contract Address"
            id="address"
            type="text"
            onChange={isEVMAddress}
            ref={addressRef}
          />



          <div class="relative inline-block text-left">
            <div>
              <button type="button" onClick={() => setDropdownHidden(!drodownHidden)} className="flex flex-row rounded-xl items-center w-[100%] justify-between gap-1 bg-white px-3 py-1 text-sm font-semibold text-navy-700 text-nowrap dark:text-black hover:bg-gray-100">
                <p className="text-nowrap">{selectedChain ? selectedChain : <div className="flex flex-row items-center gap-1"><BiLink /><p>Select Chain</p></div>}</p>
                <svg className="-mr-1 size-5 text-gray-400 w-8" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <div style={{display: drodownHidden ? "none" : "block"}} className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#393838]"><FaEthereum /> <p>Ethereum Mainnet</p></div>);setChainID("mainet");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#393838] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><FaEthereum /> <p>Ethereum Mainnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#393838]"><FaEthereum /> <p>Ethereum Sepolia</p></div>);setChainID("sepolia");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#393838] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><FaEthereum /> <p>Ethereum Sepolia</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#f4bc2c]"><SiBinance /> <p>BNB Mainnet</p></div>);setChainID("bsc");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#f4bc2c] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><SiBinance /> <p>BNB Mainnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#f4bc2c]"><SiBinance /> <p>BNB Testnet</p></div>);setChainID("testnet.bsc");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#f4bc2c] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><SiBinance /> <p>BNB Testnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#0454fc]"><img src={require("../../../assets/svg/coinbase.svg").default} style={{width: "1.2em"}} /> <p>Base Mainnet</p></div>);setChainID("base");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#0454fc] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><img src={require("../../../assets/svg/coinbase.svg").default} style={{width: "1.2em"}} /> <p>Base Mainnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#7d3ce4]"><img src={require("../../../assets/img/polygon.png")} style={{width: "1.2em"}} /> <p>Polygon Mainnet</p></div>);setChainID("poly");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#7d3ce4] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><img src={require("../../../assets/img/polygon.png")} style={{width: "1.2em"}} /> <p>Polygon Mainnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#7d3ce4]"><img src={require("../../../assets/img/polygon.png")} style={{width: "1.2em"}} /> <p>Polygon zkEVM</p></div>);setChainID("polyzk");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#7d3ce4] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><img src={require("../../../assets/img/polygon.png")} style={{width: "1.2em"}} /> <p>Polygon zkEVM</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#243444]"><img src={require("../../../assets/img/arbitrum.png")} style={{width: "1.2em"}} /> <p>Arbitrum Mainnet</p></div>);setChainID("arbi");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#243444] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><img src={require("../../../assets/img/arbitrum.png")} style={{width: "1.2em"}} /> <p>Arbitrum Mainnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#243444]"><img src={require("../../../assets/img/arbitrum.png")} style={{width: "1.2em"}} /> <p>Arbitrum Testnet</p></div>);setChainID("testnet.arbi");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#243444] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><img src={require("../../../assets/img/arbitrum.png")} style={{width: "1.2em"}} /> <p>Arbitrum Testnet</p></a>
              <a href="#" onClick={() => {setSelectedChain(<div className="flex flex-row gap-2 items-center text-sm text-[#1868ff]"><SiFantom /> <p>Fantom Mainnet</p></div>);setChainID("ftm");setDropdownHidden(true)}} className="flex flex-row gap-2 items-center px-4 py-2 text-sm text-[#1868ff] hover:bg-gray-50" role="menuitem" tabindex="-1" id="menu-item-0"><SiFantom /> <p>Fantom Mainnet</p></a>
            </div>
          </div>


          <button onClick={(e) => uploadRef.current.click() + e.preventDefault()} disabled={addressValue != ""} style={{backgroundColor: addressValue != "" ? "#dedede" : "white"}} className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
            or Upload ZIP
          </button>
          {/* style={{color: '#fc825c'}} */}
          
          <button onClick={(e) => {docUrl ? handleRedirect(e) : (scanZIP ? scanFile(e) : scanAddress(e))}} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5em'}} className={"text-black linear rounded-xl px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70" + (docUrl ? " bg-brand-500 hover:!bg-[#db8e76] text-white" : " bg-white")}><span>{docUrl ? 'Open' : 'Submit'}</span><span>{docUrl ? <FaArrowRight /> : null}</span>
            {scanLoading ?
              <Spinner 
              thickness='3px'
              speed='0.65s'
              className="w-4 h-4 text-sm shadow-xl text-brand-500"
              />
              :
              null
            }
          </button> 
          
          <button
            onClick={() => setScanZIP(null)}
            href=" "
            className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2"
          >
            {scanZIP ? scanZIP.name : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner1;
